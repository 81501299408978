.navigation{
    width:100%;
    min-height:40px;
    display:flex;
    justify-content:space-between;
    background-color:black;
    box-sizing:border-box;
    position:fixed;
    margin-top:0;
    overflow: hidden;
    top: 0;
    z-index: 999;
}
.navTitle{
    display:flex;
    align-items:center;
}
.navTitle2{
    display:none;
}
.navigation p{
    color:#FC6D6D;
    font-size: 1.4rem;
    font-weight:bolder;
    margin:0px;
}
.nav-box{
    width:400px;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
}
.links{
    color:white;
}

@media(max-width:600px){
    .navigation{
        flex-direction: column;
        min-height:150px;
        align-items:center;
        position:static;
    }
    .navTitle{
        display:none;
    }
    .navTitle2{
        display:flex;
        align-items:center;
    }
    .navigation p{
        font-size: 1.4rem;
        margin:0px;
       
    }
    .column{
        display:flex;
        flex-direction:column;
        justify-content:space-evenly;
        width:100%;
        height:100px;
    }
    .column .links{
        font-size:1.4rem;
        border-bottom:1px solid#FC6D6D;
        width:100%;
        text-align:center;
    }
    .column .links:hover{
        font-size:1.6rem;
        text-decoration:none;
    }
    .nav-box{
        margin:10px;
    }
}

.navigation.footer-style{
    position:static;
    justify-content:space-around;
    align-items:center;
    min-height:50px;
}
.topScroll{
    height:30px;
    background-color:#FC6D6D;
    border-radius:7px;
    border:none;
    padding:5px;
    font-weight:bold;
}
.topScroll:hover{
    background-color:white;
}

@media(max-width:600px){
    .navigation.footer-style{
      min-height:50px;
      flex-direction:row;
      justify-content:space-around;
    }
    .navigation.footer-style .nav-box{
        width:50%;        
    }
}

