.featured_projects{
    width:100vw;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color:rgb(246, 246, 246);
    padding-top:20px;
}
.project_list{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:column;
    width:80vw;
    min-height:100vh;
    padding:4%;
}

.project_left{
    width:45%;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
}
h2{
    font-size: 2.4rem;
    margin-top:10vh;
}

h3{
    font-size: 50px;
    color:#FC6D6D;
}
.project{
    margin:4%;
    border:1px solid black;
    padding:2%;
    border-radius:7px;
    display:flex;
    margin:2%;
    justify-content:space-around;
    align-items:center;
    width:80vw;
    background-color:rgb(246, 246, 246);
    box-sizing:border-box;
}

.project img{
    width:400px;
    height:300px;
    object-fit:cover;
    border-radius:10px;
}
.projLink {
    text-decoration: none;
    color:black;
}
.project:hover{
    background-color: #FFF;
    border:7px double black;
}
h3{
    color: #FC6D6D;
        text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 black;
}


@media(max-width:1200px){
    .project{
        width:90vw;
        margin-left:0px;
    }
    h3{
        font-size:35px;
    }
    .project h4{
        font-size:15px;
    }
}
@media(max-width:800px){
    .project{
       flex-direction:column;
       margin-top:8%;
    }
    .project_left{
        width:80%;
    }
}

@media(max-width:600px){
    .project img{
        width:80%;
    }
    .featured_projects{
        padding-top:0px;
    }
    h2{
        margin-top:5vh;
    }
}