@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  position:relative;
}

.homeImg img{
      object-fit: cover;
      border-radius:50%;
      margin-top:25%;
      position:relative;
      border:3px solid #343F4F;
      margin-bottom:0px;
} 
.homeImg{
  display:flex;
  flex-direction:column;
  text-align:center;
}
.homeImg h4{
  margin:20px;
  font-size:1.4rem;
  color:#343F4F;
}
.home{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items: center;
    padding-top:80px;
    margin-bottom:0;
    padding-bottom:0;
    min-height:100vh;
    background-image:none;
}
.home::before {    
  content: "";
  background-image:url('https://images.unsplash.com/photo-1520531158340-44015069e78e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2544&q=80');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity:.5;
}
.home h2{
    font-size: 2.5rem;  
    margin-bottom:15px;
    
}
.bridgeImg{
    width:80%;
    max-width:1000px;
    max-height:200px;
    object-fit: cover;
    margin:0px;
    display: flex;
    align-items: flex-end;
}

body{
  display: flex;
    
}
.wrapper{
  display: flex;
  margin-top:30vh;

}
.wrapper .static-txt{
  color: #343F4F;
  font-size: 60px;
  font-weight: 400;
  
}
.wrapper .dynamic-txts{
  margin-left: 15px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: #FC6D6D;
  font-size: 60px;
  font-weight: 500;
  text-align:center;
  position: relative;
  top: 0;
  animation: slide 12s steps(4) infinite;
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li span{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li span::after{
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background:white;
  opacity:0;
  border-left: 2px solid #FC6D6D;
  animation: typing 3s steps(10) infinite;
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}
h1{
  display:none;
}
@media(max-width:1200px){
  .bridgeImg{
    max-height:120px;
    max-width:800px;
  }
}
@media(max-width:600px){
  .home{
    min-height:60vh;
    padding-top:10%;
  }
  .wrapper{
    display:none;
  }
  h1{
    display:block;
    color:#FC6D6D;
    margin-top:15vh;
  }
  .bridgeImg{
    max-height:95px;
    width:100%;
  }
  .homeImg img{
   width:250px;
   height:250px;
} 
}



