@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap);
*{
  box-sizing: border-box;
  /* border:1px solid red; */
}


body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  -webkit-align-content: center;
          align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  -webkit-hyphens: auto;
          hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 110vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
  color:#FC6D6D;
  border-bottom:2px solid #343F4F;
}
p {
  font-size: 16px;
}
.navigation a {
  color: white;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
.navigation a:hover {
  border-bottom: 1px solid #FC6D6D;
  padding-bottom:5px;
}
code,
pre {
  color: #d400ff;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: '\`';
}
.app{
  width:100%;
}
@media(max-width:1000px){
  h2{
    font-size:30px;
  }
}

.navigation{
    width:100%;
    min-height:40px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    background-color:black;
    box-sizing:border-box;
    position:fixed;
    margin-top:0;
    overflow: hidden;
    top: 0;
    z-index: 999;
}
.navTitle{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
}
.navTitle2{
    display:none;
}
.navigation p{
    color:#FC6D6D;
    font-size: 1.4rem;
    font-weight:bolder;
    margin:0px;
}
.nav-box{
    width:400px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content:space-evenly;
            justify-content:space-evenly;
    -webkit-align-items:center;
            align-items:center;
}
.links{
    color:white;
}

@media(max-width:600px){
    .navigation{
        -webkit-flex-direction: column;
                flex-direction: column;
        min-height:150px;
        -webkit-align-items:center;
                align-items:center;
        position:static;
    }
    .navTitle{
        display:none;
    }
    .navTitle2{
        display:-webkit-flex;
        display:flex;
        -webkit-align-items:center;
                align-items:center;
    }
    .navigation p{
        font-size: 1.4rem;
        margin:0px;
       
    }
    .column{
        display:-webkit-flex;
        display:flex;
        -webkit-flex-direction:column;
                flex-direction:column;
        -webkit-justify-content:space-evenly;
                justify-content:space-evenly;
        width:100%;
        height:100px;
    }
    .column .links{
        font-size:1.4rem;
        border-bottom:1px solid#FC6D6D;
        width:100%;
        text-align:center;
    }
    .column .links:hover{
        font-size:1.6rem;
        text-decoration:none;
    }
    .nav-box{
        margin:10px;
    }
}

.navigation.footer-style{
    position:static;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    -webkit-align-items:center;
            align-items:center;
    min-height:50px;
}
.topScroll{
    height:30px;
    background-color:#FC6D6D;
    border-radius:7px;
    border:none;
    padding:5px;
    font-weight:bold;
}
.topScroll:hover{
    background-color:white;
}

@media(max-width:600px){
    .navigation.footer-style{
      min-height:50px;
      -webkit-flex-direction:row;
              flex-direction:row;
      -webkit-justify-content:space-around;
              justify-content:space-around;
    }
    .navigation.footer-style .nav-box{
        width:50%;        
    }
}


.featured_projects{
    width:100vw;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:center;
            justify-content:center;
    background-color:rgb(246, 246, 246);
    padding-top:20px;
}
.project_list{
    display:-webkit-flex;
    display:flex;
    -webkit-align-items:center;
            align-items:center;
    -webkit-justify-content:flex-start;
            justify-content:flex-start;
    -webkit-flex-direction:column;
            flex-direction:column;
    width:80vw;
    min-height:100vh;
    padding:4%;
}

.project_left{
    width:45%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-around;
            justify-content:space-around;
}
h2{
    font-size: 2.4rem;
    margin-top:10vh;
}

h3{
    font-size: 50px;
    color:#FC6D6D;
}
.project{
    margin:4%;
    border:1px solid black;
    padding:2%;
    border-radius:7px;
    display:-webkit-flex;
    display:flex;
    margin:2%;
    -webkit-justify-content:space-around;
            justify-content:space-around;
    -webkit-align-items:center;
            align-items:center;
    width:80vw;
    background-color:rgb(246, 246, 246);
    box-sizing:border-box;
}

.project img{
    width:400px;
    height:300px;
    object-fit:cover;
    border-radius:10px;
}
.projLink {
    text-decoration: none;
    color:black;
}
.project:hover{
    background-color: #FFF;
    border:7px double black;
}
h3{
    color: #FC6D6D;
        text-shadow: -2px -2px 0 black, 2px -2px 0 black, -2px 2px 0 black, 2px 2px 0 black;
}


@media(max-width:1200px){
    .project{
        width:90vw;
        margin-left:0px;
    }
    h3{
        font-size:35px;
    }
    .project h4{
        font-size:15px;
    }
}
@media(max-width:800px){
    .project{
       -webkit-flex-direction:column;
               flex-direction:column;
       margin-top:8%;
    }
    .project_left{
        width:80%;
    }
}

@media(max-width:600px){
    .project img{
        width:80%;
    }
    .featured_projects{
        padding-top:0px;
    }
    h2{
        margin-top:5vh;
    }
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  position:relative;
}

.homeImg img{
      object-fit: cover;
      border-radius:50%;
      margin-top:25%;
      position:relative;
      border:3px solid #343F4F;
      margin-bottom:0px;
} 
.homeImg{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  text-align:center;
}
.homeImg h4{
  margin:20px;
  font-size:1.4rem;
  color:#343F4F;
}
.home{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content:space-between;
            justify-content:space-between;
    -webkit-align-items: center;
            align-items: center;
    padding-top:80px;
    margin-bottom:0;
    padding-bottom:0;
    min-height:100vh;
    background-image:none;
}
.home::before {    
  content: "";
  background-image:url('https://images.unsplash.com/photo-1520531158340-44015069e78e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2544&q=80');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity:.5;
}
.home h2{
    font-size: 2.5rem;  
    margin-bottom:15px;
    
}
.bridgeImg{
    width:80%;
    max-width:1000px;
    max-height:200px;
    object-fit: cover;
    margin:0px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end;
}

body{
  display: -webkit-flex;
  display: flex;
    
}
.wrapper{
  display: -webkit-flex;
  display: flex;
  margin-top:30vh;

}
.wrapper .static-txt{
  color: #343F4F;
  font-size: 60px;
  font-weight: 400;
  
}
.wrapper .dynamic-txts{
  margin-left: 15px;
  height: 90px;
  line-height: 90px;
  overflow: hidden;
}
.dynamic-txts li{
  list-style: none;
  color: #FC6D6D;
  font-size: 60px;
  font-weight: 500;
  text-align:center;
  position: relative;
  top: 0;
  -webkit-animation: slide 12s steps(4) infinite;
          animation: slide 12s steps(4) infinite;
}
@-webkit-keyframes slide {
  100%{
    top: -360px;
  }
}
@keyframes slide {
  100%{
    top: -360px;
  }
}
.dynamic-txts li span{
  position: relative;
  margin: 5px 0;
  line-height: 90px;
}
.dynamic-txts li span::after{
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background:white;
  opacity:0;
  border-left: 2px solid #FC6D6D;
  -webkit-animation: typing 3s steps(10) infinite;
          animation: typing 3s steps(10) infinite;
}
@-webkit-keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}
@keyframes typing {
  40%, 60%{
    left: calc(100% + 30px);
  }
  100%{
    left: 0;
  }
}
h1{
  display:none;
}
@media(max-width:1200px){
  .bridgeImg{
    max-height:120px;
    max-width:800px;
  }
}
@media(max-width:600px){
  .home{
    min-height:60vh;
    padding-top:10%;
  }
  .wrapper{
    display:none;
  }
  h1{
    display:block;
    color:#FC6D6D;
    margin-top:15vh;
  }
  .bridgeImg{
    max-height:95px;
    width:100%;
  }
  .homeImg img{
   width:250px;
   height:250px;
} 
}




